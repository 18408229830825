import { Button } from "@chakra-ui/button";
import { Heading, Stack } from "@chakra-ui/layout";
import React from "react";
import { signIn } from "next-auth/react";
import { Page } from "components/page";
import { useRouter } from "next/dist/client/router";
import { CustomHead } from 'components/custom-head'

export default function Custom404 () {
  const baseUrl =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:3003"
      : "https://app.taplio.com";

  const router = useRouter();

  return (
    <Page>
      <CustomHead noIndex={true} />
      <Stack display="flex" flexDirection="column" alignItems="center" maxW={400} p={50}  spacing={10} margin="auto">
        <Heading fontSize="2xl" textAlign="center">
          Oups, there is nothing here
        </Heading>
        <Button
          maxW={200}
          colorScheme="blue"
          onClick={() => {
            router.push("/signin");
          }}
        >
          Go back
        </Button>
      </Stack>
    </Page>
  );
};
